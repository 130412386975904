<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="newNotifications" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge pill variant="light-primary"> {{ newNotifications ? newNotifications : 0 }} New </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar v-if="notificationsList.length > 0" :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
      <!-- Account Notification -->
      <b-link v-for="(value, key) in notificationsList" :key="key" @click="directTo(value, key)">
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="value.data.color">
              <feather-icon :icon="value.data.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ value.data.title }}
            </span>
          </p>
          <small class="notification-text">{{ value.data.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer" v-show="notificationsList.length > 0">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="readAllNotifications">Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import axiosIns from '@/libs/axios';
import router from '@/router';
import { isNavigationFailure, NavigationFailureType } from 'vue-router/src/util/errors';
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    axiosIns,
  },
  data() {
    return {
      route: router.currentRoute.name,
      userData: JSON.parse(localStorage.getItem('userData')),
      newNotifications: null,
      notificationsList: [],
    };
  },
  directives: {
    Ripple,
  },
  created() {
    if (this.$socket != 'close') {
      this.$socket.on('notifications', () => {
        this.getNotifications();
      });
    }
  },

  mounted() {
    this.getNotifications();
  },

  methods: {
    getNotifications() {
      axiosIns
        .get(`getNotifications/${this.userData.id}`)
        .then((response) => {
          this.newNotifications = response.data.length;
          (this.notificationsList = []),
            response.data.forEach((element) => {
              this.notificationsList.push(element);
            });
        })
        .catch();
    },
    directTo(value, key) {
      this.notificationsList.splice(key, 1);
      this.newNotifications = this.newNotifications - 1;

      switch (value.data.type) {
        case 'App\\Models\\PurchaseQuote':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'quote-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\SaleQuote':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();

          router.push({ name: 'sales-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'inventory':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'inventory-view', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'lead':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'leads-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'customer':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'customer-edit', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'user':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'user-profile', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\Income':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'income-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\Expense':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'expense-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\VehicleAdjustment':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'adjustment-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\Inception':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'testlease-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\Offroad':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'offroad-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;
        case 'App\\Models\\LeasePayments':
          axiosIns
            .get(`readNotification/${value.id}`)
            .then((response) => {})
            .catch();
          router.push({ name: 'payment-preview', params: { id: value.data.recordId } }).catch((error) => {
            if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
              throw error;
            } else {
              // this.notificationsList.splice(key, 1);
              // this.newNotifications = this.newNotifications - 1;
            }
          });
          break;

        default:
          break;
      }
    },
    readAllNotifications() {
      axiosIns
        .get(`readAllNotifications`)
        .then((response) => {})
        .catch();
      this.notificationsList = [];
      this.newNotifications = null;
    },
  },
  setup() {
    /* eslint-disable global-require */
    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style></style>
