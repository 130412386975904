export default [
  {
    header: 'APP MENU',
  },
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Customers',
    icon: 'UsersIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'customer-add',
        icon: 'UserPlusIcon',
        Permission: 'customer_create',
      },
      {
        title: 'List',
        route: 'customer-list',
        icon: 'ListIcon',
        Permission: 'customer_list',
      },
      {
        title: 'Edit',
        route: 'customer-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'customer_show',
      },
    ],
  },

  {
    title: 'Inventory',
    icon: 'BoxIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'inventory-add',
        icon: 'CodesandboxIcon',
        Permission: 'inventory_create',
      },

      {
        title: 'List',
        route: 'inventory-list',
        icon: 'ClipboardIcon',
        Permission: 'inventory_list',
      },
      {
        title: 'List2',
        route: 'inventory-list2',
        icon: 'ClipboardIcon',
        Permission: 'inventory_list',
      },
      {
        title: 'Web List',
        route: 'web-list',
        icon: 'ClipboardIcon',
        Permission: 'publish',
      },
      {
        title: 'Edit',
        route: 'inventory-edit',
        icon: 'InboxIcon',
        disabled: true,
        Permission: 'inventory_edit',
      },
      // {
      //       title: 'Published Vehicles',
      //       route: 'inventory-publishedit',
      //       icon: 'RssIcon',
      //       action: 'all',
      //       Permission: 'inventory_edit'
      //     },
    ],
  },

  {
    title: 'Purchase',
    icon: 'PlusSquareIcon',
    Permission: '',
    children: [
      {
        title: 'Create Quote',
        route: 'quote-add',
        icon: 'HashIcon',
        Permission: 'purchase_quote_create',
      },

      {
        title: 'Quote List',
        route: 'quote-list',
        icon: 'DatabaseIcon',
        Permission: 'purchase_quote_list',
      },

      {
        title: 'Edit',
        route: 'quote-edit',
        icon: 'InboxIcon',
        disabled: true,
        Permission: 'purchase_quote_edit',
      },

      /*  {
              title: 'Process',
              route: 'purchase-add',
              icon: 'CodesandboxIcon',
              action: 'create',
              resource: 'purchase',
            },
            {
              title: 'List',
              route: 'purchase-list',
              icon: 'ClipboardIcon',
              action: 'read',
              resource: 'purchase',
            },
            {
              title: 'Edit',
              route: 'purchase-edit',
              icon: 'InboxIcon',
              disabled: true,
              action: 'write',
              resource: 'purchase',
            },*/
    ],
  },

  {
    title: 'Sale',
    icon: 'MinusSquareIcon',
    Permission: '',
    children: [
      {
        title: 'Create Quote',
        route: 'sales-add',
        icon: 'HashIcon',
        Permission: 'sale_quote_create',
      },

      {
        title: 'Quote List',
        route: 'sales-list',
        icon: 'DatabaseIcon',
        Permission: 'sale_quote_list',
      },

      {
        title: 'Edit',
        route: 'sales-edit',
        icon: 'InboxIcon',
        disabled: true,
        Permission: 'sale_quote_edit',
      },
    ],
  },

  {
    title: 'Lease',
    icon: 'ShuffleIcon',
    Permission: '',

    children: [
      {
        title: 'Inception',
        route: 'testlease-list',
        icon: 'DatabaseIcon',
        Permission: 'inception_quote_list',
      },

      {
        title: 'Return',
        route: 'offroad-list',
        icon: 'DatabaseIcon',
        Permission: 'offroad_quote_list',
      },

      {
        title: 'Payments',
        route: 'payment-list',
        icon: 'DatabaseIcon',
        Permission: 'inception_quote_list',
      },
      {
        title: 'Leased Inventory',
        route: 'testlease-inventory',
        icon: 'DatabaseIcon',
        Permission: 'inception_quote_list',
      },
    ],
  },

  {
    title: 'Leads',
    icon: 'InboxIcon',
    Permission: '',
    children: [
      {
        title: 'Create Lead',
        route: 'leads-add',
        icon: 'DatabaseIcon',
        Permission: 'lead_create',
      },

      {
        title: 'Leads List',
        route: 'leads-list',
        icon: 'HashIcon',
        Permission: 'lead_access',
      },

      {
        title: 'Edit',
        route: 'leads-edit',
        icon: 'InboxIcon',
        disabled: true,
        Permission: 'lead_edit',
      },
    ],
  },

  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'user-add',
        icon: 'UserPlusIcon',
        Permission: 'user_create',
      },
      {
        title: 'List',
        route: 'user-list',
        icon: 'ListIcon',
        Permission: 'user_list',
      },
      {
        title: 'Edit',
        route: 'user-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'user_edit',
      },
    ],
  },

  {
    title: 'Orders',
    icon: 'UsersIcon',
    Permission: 'order_list',
    children: [
      {
        title: 'Purchase Orders',
        route: 'purchase-order',
        icon: 'UserPlusIcon',
        Permission: 'order_list',
      },

      {
        title: 'Sale Orders',
        route: 'sale-order',
        icon: 'UserPlusIcon',
        Permission: 'order_list',
      },

      {
        title: 'Vehicle Adjustment',
        route: 'vehicle-adjustment',
        icon: 'UserPlusIcon',
        Permission: 'order_list',
      },
    ],
  },

  {
    title: 'Accounting',
    icon: 'UsersIcon',
    Permission: '',
    children: [
      {
        title: 'Business Overview',
        route: 'reports',
        icon: 'UserPlusIcon',
        Permission: 'view_business_overview',
      },
      {
        title: 'Sales Report',
        route: 'report-inventory',
        icon: 'UserPlusIcon',
        Permission: 'view_sale_report',
      },
      {
        title: 'Stock Report',
        route: 'report-stock',
        icon: 'UserPlusIcon',
        Permission: 'view_inventory_report',
      },
      // {
      //   title: 'Profit and Loss',
      //   route: 'report-profitloss',
      //   icon: 'UserPlusIcon',
      //   Permission: 'view_profit_loss',
      // },
      {
        title: 'Comprehensive Income',
        route: 'report-comprehensive-income',
        icon: 'UserPlusIcon',
        Permission: 'view_profit_loss',
      },
      {
        title: 'Financial Position',
        route: 'report-financial-position',
        icon: 'UserPlusIcon',
        Permission: 'view_profit_loss',
      },
      {
        title: 'General Journal',
        route: 'report-journal',
        icon: 'UserPlusIcon',
        Permission: 'view_profit_loss',
      },

      // {
      //   title: 'Chart of Accounts',
      //   route: 'account-list',
      //   icon: 'UserPlusIcon',
      //   Permission: 'view_profit_loss',
      // },
    ],
  },

  {
    title: 'Settings',
    icon: 'SettingsIcon',
    Permission: 'setting_list',
    route: 'settings',
  },

  {
    title: 'Chat',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
    Permission: '',
  },
];
