<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive"> <feather-icon icon="MenuIcon" size="21" /> </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <feather-icon icon="ArrowLeftIcon" size="28" @click="backRouter()" class="mr-2 text-primary cursor-pointer" />
      <feather-icon icon="ArrowRightIcon" size="28" @click="nextRouter()" class="text-primary cursor-pointer" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <feather-icon :badge="unseenMsgs" badge-classes="bg-primary" @click="gotoChat()" class="text-body cursor-pointer" icon="MessageSquareIcon" size="21" />

      <notification-dropdown class="ml-1" />
      <dark-Toggler class="d-none d-lg-block" />
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ userData.name }} {{ userData.surname }}</p>
            <span class="user-status" v-if="userData.role == 'supermanager'">Super Manager</span>
            <span class="user-status" v-else>{{ userData.role }}</span>
          </div>

          <b-avatar size="40" variant="light-primary" badge :src="profileURL" class="badge-minimal" badge-variant="success" />
          <!-- <b-avatar size="40" variant="light-primary" badge src="" class="badge-minimal" badge-variant="success" />  -->
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="navigateProfile">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>
            <b-link class="alert-link mt-5 mb-5">
              Logout
            </b-link></span
          >
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import store from '@/store';
import axiosIns from '@/libs/axios';
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue';

export default {
  data() {
    return {
      route: router.currentRoute.name,
      unseenMsgs: 0,
      email: localStorage.getItem('email'),
      userData: JSON.parse(localStorage.getItem('userData')),
      profileURL: store.state.app.profileURL + JSON.parse(localStorage.getItem('userData')).profile,
    };
  },

  created() {
    if (this.$socket != 'close') {
      if (this.$socket.connected == false) {
        var userId = this.userData.id;
        this.$socket.auth = { userId };
        this.$socket.connect();

        this.$socket.on('USERS', (users) => {
          localStorage.setItem('chatUserList', JSON.stringify(users));
        });

        this.$socket.on('notification_sent', (data) => {});
      }

      axiosIns
        .get('unseenMsgs')
        .then((res) => {
          this.unseenMsgs = res.data;
          this.$socket.on('USERS', (users) => {
            // localStorage.setItem('chatUserList', JSON.stringify(users));
            localStorage.setItem('unseenMsgs', this.unseenMsgs);
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$socket.on('USER_DISCONNECTED', (user) => {
        this.$chatUserList = this.$chatUserList.filter((object) => {
          return object.socketId !== user.socketId;
        });
      });

      this.$socket.on('SEND_MESSAGE', (content) => {
        if (content.receiverUserId == this.userData.id) {
          axiosIns
            .get('unseenMsgs')
            .then((res) => {
              this.unseenMsgs = res.data;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  },

  destroyed() {
    if (this.$socket != 'close') {
      this.$socket.off('connect_error');
    }
  },

  methods: {
    backRouter() {
      router.go(-1);
    },

    nextRouter() {
      router.go(1);
    },

    gotoChat() {
      if (router.currentRoute.name != 'apps-chat') {
        this.$router.push({ name: 'apps-chat' });
      }
    },

    navigateProfile() {
      if (router.currentRoute.name != 'user-profile') {
        this.$router.push({ name: 'user-profile' });
      }
    },

    logout() {
      if (this.$socket != 'close') {
        this.$socket.emit('LOGOUT_USER');
      }

      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData');

      // Reset ability
      /*
      this.$ability.update(initialAbility);
      */

      // Redirect to login page
      this.$router.push({ name: 'auth-login' });
    },
  },

  computed: {},

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
